<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="提现日期">
          <el-date-picker
            v-model="dataForm.takeGoodsTime"
            type="daterange"
            align="right"
            unlink-panels
            @change="getDataList"
            range-separator="-"
            size="small"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="提现手机号">
            <el-input v-model="dataForm.cardTypeName" placeholder="提现手机号"></el-input>
        </el-form-item>
        <el-form-item label="司机姓名">
            <el-input v-model="dataForm.cardTypeName" placeholder="司机姓名"></el-input>
        </el-form-item>
        <el-form-item label="所属车队">
          <el-select class="item-choose" v-model="dataForm.motorcadeId" filterable clearable placeholder="请选择">
            <el-option
              v-for="item in motorcadeList"
              :key="item.id"
              :label="item.simpleName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button v-if="isAuth('sys:user:save')" type="primary" @click="addOrUpdateHandle('add')">新增</el-button>
          <el-button v-if="newAuth('order:inside:audit')" size="small" @click="operatopnList('review')" type="success" >批量审核</el-button>
          <el-button v-if="isAuth('sys:user:save')" type="primary" @click="operatopnList('transfer')" >批量已转账</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table height="72vh" :row-class-name="tableRowClassName" @row-click="rowClick" :row-style="selectedHighlight"  :data="dataList" border size="small" v-loading="dataListLoading" @selection-change="selectionChangeHandle" style="width: 100%;">
        <el-table-column type="selection" width="25" fixed="left" align="center"></el-table-column>
        <el-table-column prop="cardObject" label="申请提现日期" :formatter="cardType" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="name" label="申请提现手机号" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="name" label="司机姓名" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="name" label="所属车队" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="name" label="总订单金额" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="name" label="已提现金额" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="name" label="可提现金额" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="name" label="本地申请提现金额" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="name" label="余额" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="status" label="提现方式">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 2" size="small" type="danger">银行卡</el-tag>
            <el-tag v-else size="small" type="success">微信</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="收款账户名" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="name" label="收款账号" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="name" label="收款银行" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="status" label="审核状态" fixed="right" >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 2" size="small" type="danger">待审核</el-tag>
            <el-tag v-else size="small" type="success">已审核</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="转款状态" fixed="right" >
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 2" size="small" type="danger">未转款</el-tag>
            <el-tag v-else size="small" type="success">已转款</el-tag>
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="40" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <!-- <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="addOrUpdateHandle('view', scope.row.id)">详情</el-button> -->
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="operatopnList('review', scope.row)">审核</el-button>
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="operatopnList('transfer', scope.row)">已转款</el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from './detail/withdrawwals-detail'
export default {
  data () {
    return {
      dataForm: {
        name: null,
        cardObject: null,
        cardTypeName: null,
        status: null
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      getIndex: -1,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      detailVisible: false,
      motorcadeList: []
    }
  },
  components: {
    AddOrUpdate
  },
  activated () {
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  mounted() {
    this.$http({
      url: this.$http.adornUrl('/motorcade/findListBySelect'),
      method: 'get',
      params: this.$http.adornParams()
    }).then(({ data }) => {
      this.motorcadeList = data
    })
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex ) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex;
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex=row.index
    },
    /**
     * 点击表格变色end
     */
    operatopnList (type, row)  {
      let that = this
      let ids = []
      let name = ''
      if(row) {
        ids.push(row.id)
      } else {
        this.dataListSelections.forEach(item => {
          ids.push(item.id)
        })
      }
      if(type == 'review') {
        name = '审核'
      } else {
        name = '已转账'
      }
      this.$confirm('你正在进行【' + name + '】操作, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if(type == 'review') {
          that.$http({
            url: that.$http.adornUrl(`/waybill/waybillAudit`),
            method: 'POST',
            data: that.$http.adornData(ids)
          }).then(({ data }) => {
            if (data) {
              that.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  that.visible = false
                  that.$emit('refreshDataList')
                }
              })

            } else {
              that.$message.error(res.data.msg)
            }
          })
        } else {
          that.$http({
            url: that.$http.adornUrl(`/waybill/waybillAudit`),
            method: 'POST',
            data: that.$http.adornData(ids)
          }).then(({ data }) => {
            if (data) {
              that.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  that.visible = false
                  that.$emit('refreshDataList')
                }
              })

            } else {
              that.$message.error(res.data.msg)
            }
          })
        }
      }).catch(() => {})
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl('/cardType/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'name': this.dataForm.name,
          'status': this.dataForm.status,
          'cardObject': this.dataForm.cardObject,
          'cardTypeName': this.dataForm.cardTypeName
        })
      }).then(({data}) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (id) {
      this.detailVisible = true
      this.$nextTick(() => {
        this.$refs.detail.init(id)
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (todo, id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(todo, id)
      })
    },
    // 禁用或启用
    disableOrEnable (row) {
      let title = row.status === 1 ? '禁用' : '启用'
      this.$confirm('确定对【' + row.name + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/cardType/updateStatus'),
          method: 'post',
          data: {
            id: row.id,
            status: row.status === 1 ? 2 : 1
          }
        }).then(({data}) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    },
    // 删除
    deleteHandle (id) {
      let ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/sys/user/delete'),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>
